import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule, PLATFORM_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { APP_BASE_HREF, DecimalPipe, isPlatformBrowser } from '@angular/common';
import { SnackBarService } from './modules/shared/services/snack-bar.service';
import { DynamicScript } from './modules/shared/services/dynamic-script.service';
import { HttpInterceptorService } from './modules/shared/services/interceptor/http-interceptor.service';
import { ErrorHandlerService } from './modules/shared/services/interceptor/error-handler.service';
import { FooterComponent } from './modules/shared/components/footer/footer.component';
import { NavigationComponent } from './modules/shared/components/navigation/navigation.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { AccessDeniedComponent } from './modules/category/components/access-denied/access-denied.component';
import { WrapperComponent } from './modules/shared/components/wrapper/wrapper.component';
import { ForbiddenRoutes } from './modules/shared/enum/forbidden-route';
import { MatDialogModule } from '@angular/material/dialog';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule } from 'ng-lazyload-image';
import { LazyLoadService } from './modules/shared/services/lazy-load.service';
import { ErrorLogger } from './modules/shared/services/sentry-error-logger.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MobileNavigationComponent } from './modules/shared/components/mobile-navigation/mobile-navigation.component';
import { MatInputModule } from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { InfiniteScrollProviderComponent } from './modules/shared/components/infinite-scroll-provider/infinite-scroll-provider.component';
import { SharedModule } from './modules/shared/shared.module';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

function generateBaseUrl() : String{
  let subPath = window.location.pathname.split('/')[1];
  let isLocalRoute = ForbiddenRoutes.find(el => el == subPath);
  return (isLocalRoute || !subPath) ? '' : `${subPath}`;
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    AccessDeniedComponent,
    WrapperComponent,
    MobileNavigationComponent,
    InfiniteScrollProviderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    LazyLoadImageModule,
    ScrollingModule,
    MatAutocompleteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    SnackBarService,
    DynamicScript,
    DecimalPipe,
    // {
    //   provide: APP_BASE_HREF,
    //   useValue: '/' + generateBaseUrl(),
    // },
    {
      provide: APP_BASE_HREF,
      useFactory: (platformId: Object) => {
        if (isPlatformBrowser(platformId)) {
          return  '/' + generateBaseUrl()
        } else {
          return '';
        }
      },
      deps: [PLATFORM_ID],
    },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadService },
    {
      provide: ErrorHandler,
      useClass: ErrorLogger
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerService,
      multi: true,
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
